function createLocalDownload(data: Blob, name: string): void {
  const link = document.createElement("a");
  link.href = URL.createObjectURL(data);
  link.download = name;
  link.click();
  URL.revokeObjectURL(link.href);
}

function coerceValidFileName(str: string): string {
  return str.toLocaleLowerCase().replaceAll(" ", "_");
}

export { createLocalDownload, coerceValidFileName };
